import React, { useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

// Components
import LoadMoreButton from '../components/Common/Button/LoadMoreButton';
import NewsItem from '../components/News/NewsItem';
import NewsList from '../components/News/NewsList';
import Section from '../components/Common/Section/Section';

import LayoutArchive from '../components/Layouts/LayoutArchive';

const ArchiveProjects = (props) => {
  const { pageContext } = props;
  const { restData, catData } = pageContext;

  // Set the number of inital posts to display
  const [postsToShow, setPostsToShow] = useState(6);

  function handleLoadMore() {
    setPostsToShow(postsToShow + 6);
  }

  return (
    <LayoutArchive
      backgroundImage={catData.acf.page_hero_bg}
      yoastMeta={catData.yoast_meta}
      title={`${catData.name} Projects`}
    >
      <main>
        <Section backgroundColor="#E6ECEF">
          <NewsList>
            {restData.slice(0, postsToShow).map((item) => (
              <NewsItem
                baseSlug="projects"
                taxSlug="category"
                categories={item._embedded['wp:term'][0]}
                date={item.date}
                image={item.acf.featured_image}
                intro={item.acf.intro}
                key={shortid.generate()}
                slug={`/projects/${item.slug}`}
                title={item.title.rendered}
              />
            ))}
          </NewsList>

          {postsToShow <= restData.length && (
            <LoadMoreButton onClick={handleLoadMore}>Load more</LoadMoreButton>
          )}
        </Section>
      </main>
    </LayoutArchive>
  );
};

export default ArchiveProjects;

ArchiveProjects.propTypes = {
  pageContext: PropTypes.shape({
    restData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    catData: PropTypes.shape({
      yoast_meta: PropTypes.shape({
        yoast_wpseo_facebook_description: PropTypes.string,
        yoast_wpseo_facebook_image: PropTypes.string,
        yoast_wpseo_facebook_title: PropTypes.string,
        yoast_wpseo_metadesc: PropTypes.string,
        yoast_wpseo_title: PropTypes.string,
        yoast_wpseo_twitter_description: PropTypes.string,
        yoast_wpseo_twitter_image: PropTypes.string,
        yoast_wpseo_twitter_title: PropTypes.string,
      }).isRequired,
      name: PropTypes.string.isRequired,
      acf: PropTypes.any.isRequired,
    }).isRequired,
  }).isRequired,
};
