import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Components
import Footer from '../Footer/Footer';
import HeroHeader from '../Hero/HeroHeader';
import Menu from '../Header/Menu/Menu';
import SEO from '../Common/SEO';

const LayoutArchive = ({
  children,
  title,
  backgroundImage,
  heroTitle,
  imageSlider,
  imageSliderImages,
  parentPage,
  yoastMeta,
}) => {
  // React hooks
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function handleMenuOpen() {
    setIsMenuOpen(true);
  }

  function handleMenuClose() {
    setIsMenuOpen(false);
  }

  return (
    <>
      <SEO
        title={yoastMeta.yoast_wpseo_title || title}
        description={yoastMeta.yoast_wpseo_metadesc}
        ogDesc={yoastMeta.yoast_wpseo_facebook_description}
        ogImg={yoastMeta.yoast_wpseo_facebook_image}
        ogTitle={yoastMeta.yoast_wpseo_facebook_title}
        twitterDesc={yoastMeta.yoast_wpseo_twitter_description}
        twitterImg={yoastMeta.yoast_wpseo_twitter_image}
        twitterTitle={yoastMeta.yoast_wpseo_twitter_title}
      />

      <Menu handleMenuClose={handleMenuClose} isMenuOpen={isMenuOpen} />

      <HeroHeader
        backgroundImage={backgroundImage}
        handleMenuOpen={handleMenuOpen}
        heroTitle={heroTitle || title}
        imageSlider={imageSlider}
        imageSliderImages={imageSliderImages}
        parentPage={parentPage}
      />

      <div>{children}</div>

      <Footer />
    </>
  );
};

LayoutArchive.defaultProps = {
  data: null,
  heroTitle: '',
  imageSlider: null,
  imageSliderImages: null,
  title: null,
  backgroundImage: null,
  parentPage: null,
};

LayoutArchive.propTypes = {
  backgroundImage: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({}),
  heroTitle: PropTypes.string,
  imageSlider: PropTypes.bool,
  imageSliderImages: PropTypes.arrayOf(PropTypes.shape()),
  parentPage: PropTypes.string,
  title: PropTypes.string,
  yoastMeta: PropTypes.shape({
    yoast_wpseo_twitter_title: PropTypes.string,
    yoast_wpseo_twitter_image: PropTypes.string,
    yoast_wpseo_twitter_description: PropTypes.string,
    yoast_wpseo_facebook_title: PropTypes.string,
    yoast_wpseo_facebook_image: PropTypes.string,
    yoast_wpseo_facebook_description: PropTypes.string,
    yoast_wpseo_metadesc: PropTypes.string,
    yoast_wpseo_title: PropTypes.string,
  }).isRequired,
};

export default LayoutArchive;
